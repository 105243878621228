import React from 'react'
import Navbar from '../components/Navbar';
import Comp from '../components/Comp';

export default function ProductEpmtyState() {
  return (
    <div>
        <Navbar></Navbar>
        <form className='pes-form'>
            <p>
              We've got something cooking.
            </p>
            <input type='email' placeholder='Enter email to join the waitlist'/>
            <button>
              Join
            </button>
        </form>
        <Comp></Comp>
    </div>
  )
}
